











































import Vue from "vue"
import { mapGetters } from "vuex"

import { GameMixin } from "@/mixins"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import SlideFadeTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"

import { RtbButton } from "@/components/ui"

import useCharades from "./useCharades"

import { serialize } from "@/helpers"

export default Vue.extend({
  name: "Charades",
  mixins: [GameMixin],
  components: {
    SlideFadeTransition,
    TextField,
    RtbButton,
    ResizableText
  },
  setup() {
    const {
      getTeamAnswer,
      getTeamInput,
      isStarted,
      isFinished,
      getIsCompleted,
      databaseRef
    } = useCharades()
    return {
      getTeamAnswer,
      getTeamInput,
      isStarted,
      isFinished,
      getIsCompleted,
      databaseRef
    }
  },
  computed: {
    ...mapGetters(["missionPlaysArray", "isScribe", "gameID", "orgID"]),
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapGetters({
      // answers: "missionPlaysArray",
      teams: "chats"
    }),
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    instructions() {
      if (this.isScribe && this.isStarted) {
        return this.getTeamAnswer(this.user)?.title
      }

      return this.currentMission.instructions
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    isTextFieldVisible() {
      return !this.isHost && !this.isScribe && this.isStarted
    }
  },
  methods: {
    toggleStart() {
      this.databaseRef.update({ started: !this.isStarted })
    },
    toggleEnd() {
      this.databaseRef.update({ finished: true, started: false })
    },
    async onSubmit(text) {
      if (!text) return

      const normalized = serialize(text)

      if (this.getTeamInput(this.user).includes(normalized)) return

      this.answer = normalized
      const answewrs = this.getTeamAnswer(this.user)?.variants || []

      this.gotItRight = answewrs.includes(this.answer)

      await this.checkAnswer()
    }
  }
})
