var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "charades" },
    [
      _c("ResizableText", {
        staticClass: "charades__text capitalize",
        attrs: { message: _vm.instructions, transition: false },
      }),
      _c(
        "SlideFadeTransition",
        [
          _vm.isTextFieldVisible
            ? _c("TextField", {
                staticClass: "charades__field",
                attrs: {
                  size: "sm",
                  placeholder: "Type here ...",
                  allowFreeSubmit: "",
                  disabled: _vm.getIsCompleted(_vm.user),
                  status: "active",
                  buttonText: "Submit",
                  maxTextLength: 120,
                },
                on: { onSubmit: _vm.onSubmit },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.isHost && !_vm.isFinished
            ? _c(
                "RtbButton",
                {
                  staticClass: "font-bold text-lg px-5 mb-2 mr-2",
                  on: { click: _vm.toggleStart },
                },
                [_vm._v(" " + _vm._s(_vm.isStarted ? "Pause" : "Start") + " ")]
              )
            : _vm._e(),
          _vm.isHost && _vm.isStarted
            ? _c(
                "RtbButton",
                {
                  staticClass: "font-bold text-lg px-5 mb-2",
                  on: { click: _vm.toggleEnd },
                },
                [_vm._v(" End ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }